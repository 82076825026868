import { createContext, useState } from 'react';

const AuthContext = createContext({
    isAuthenticated: false,
    token: null,
    login: () => {},
    logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  const login = (newToken) => {
    setIsAuthenticated(true);
    setToken(newToken);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';

import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';

const categories = [
  {
    id: 'Gestion des utilisateurs',
    children: [
      { id: 'Liste des utilisateurs', icon: <PersonIcon /> },
      
    ],
  },

  {
    id: 'Statistique',
    children: [
      { id: 'Statistique', icon: <BarChartIcon /> },
      
    ],
  },


  {
    id: 'Gestion des Licences',
    children: [
      { id: 'Liste des licences (All)', icon: <ListIcon /> },
      { id: 'Liste des licences', icon: <ListAltIcon /> },
      { id: 'Génération licences', icon: <ChangeCircleIcon /> },
    ],
  },

  
];

const item = {
  py: '10px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 2.5,
  px: 3,
};

export default function Navigator(props) {
  const { setContentComponent, ...other } = props; // Ajout de "setContentComponent" comme propriété

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Licence Pro
        </ListItem>

        <ListItem sx={{ ...item, ...itemCategory }}>

        <ListItemButton sx={item} onClick={() => setContentComponent()}> {/* Ajout de onClick pour gérer le changement de contenu */}
                  <ListItemIcon> <HomeIcon /></ListItemIcon>
                  <ListItemText>Tableau de bord</ListItemText>
                </ListItemButton>

          
        </ListItem>


        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon }) => (
              <ListItem disablePadding key={childId}  >
                <ListItemButton sx={item} onClick={() => setContentComponent(childId)}> {/* Ajout de onClick pour gérer le changement de contenu */}
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}

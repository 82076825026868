import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./AuthContext"; // Importez le contexte d'authentification si nécessaire

const PrivateRoute = ({ children, redirectTo, ...props }) => {
  const { isAuthenticated } = useContext(AuthContext); // Récupérez la valeur isAuthenticated du contexte

  return isAuthenticated ? (
    children // L'utilisateur est connecté, affichez le contenu protégé
  ) : (
    <Navigate
      to={redirectTo || "/login"}
      //state={{ from: props.location.pathname }} // Enregistrez l'emplacement actuel pour y revenir après la connexion
    />
  );
};

export default PrivateRoute;

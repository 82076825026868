import * as React from 'react';

import Paper from '@mui/material/Paper';


export default function Content() {

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    fontSize: '24px',
    fontWeight: 'bold',
    backgroundColor: '#f0f0f0',
    padding: '20px',
    textAlign: 'center',
  };


  return (
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
     
      <div style={containerStyle}>

        Bienvenue dans l'application de gestion interne de licences pour PRO EDU, Begotech Burkina
      </div>


     
    </Paper>
  );
}

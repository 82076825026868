import './App.css';
import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/login";
import AuthContext  from "./pages/AuthContext"; 
import PrivateRoute from "./pages/PrivateRoute";
import { AuthProvider } from './pages/AuthContext';
function App() {
  const { isAuthenticated } = useContext(AuthContext);



  return (
    <div className="App">
    <AuthProvider>
        
        <Routes>
          
          <Route
            path="/"
            element={
              <PrivateRoute isAllowed={isAuthenticated}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
        </AuthProvider>
    </div>
  );
}

export default App;

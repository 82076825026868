// Connexion.js

import React, { useState, useContext  } from 'react';
import axios from 'axios';
import API_URL from './../configuration/config';
import Popup from './../components/popup'; // Assurez-vous de mettre le bon chemin d'accès
import { useNavigate } from 'react-router-dom'; 
import './../styles/login.css';
import AuthContext from "./AuthContext"; 



const Login = () => {


 const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    message: '',
    type: ''
  });

  
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour gérer le mode "loading"
  

  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Activer le mode "loading"

    axios.post(`${API_URL}/login`, formData)
    .then(({ data }) => {
      setIsLoading(false);

      console.log(data.token);
      login(data.token);

      navigate("/");
    })
    .catch((err) => {
      setIsLoading(false);
      setPopupData({
        isOpen: true,
        message: err.response.data.error,
        type: 'error'
      });
   
    });




  };

  const closePopup = () => {
    setPopupData({
      isOpen: false,
      message: '',
      type: ''
    });
  };

  return (
    <div>
      <h2>Formulaire de connexion</h2>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="email">Email :</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />

        <label htmlFor="password">Mot de passe :</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
        />

        {/* Le bouton passe en mode "loading" si isLoading est true */}
        <button type="submit" disabled={isLoading}>
          {isLoading ? ' Chargement...' : 'Se connecter'}
        </button>
      
      </form>
      <div>
     
    </div>
  
      {/* Modals */}
      <Popup
        open={popupData.isOpen}
        onClose={closePopup}
        message={popupData.message}
        type={popupData.type}
      />

    
    </div>
  );
};

export default Login;




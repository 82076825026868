import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

import AuthContext from "../pages/AuthContext";


const LicenceListAll = () => {
  const [licences, setLicences] = useState([]);
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    };

    axios.get('https://api.begotech.com/api/v1/licencesall', config)
      .then(response => {
        setLicences(response.data.data);
        setLoading(false); // Indique que les données ont été chargées et désactive l'indicateur de chargement
        console.log(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données des licences :', error);
        console.log(token);
        setLoading(false); // Indique que les données ont été chargées (même si une erreur s'est produite) et désactive l'indicateur de chargement
      });
  }, [token]);

  const columns = [
    { name: 'Code Licence', selector: row => row.code_licence, sortable: true },
    { name: 'Date Activation', selector: row => row.date_activation, sortable: true },
    { name: 'Date Expiration', selector: row => row.date_expiration, sortable: true },
    { name: 'Revendeur', selector: row => row.revendeur, sortable: true },
    { name: 'Client', selector: row => row.client, sortable: true },
    { name: 'Telephone', selector: row => row.telephone, sortable: true },
  ];

  return (
    <div>
      <h1>Liste des Licences (All)</h1>

      {loading ? (
        // Affiche l'indicateur de chargement si les données sont en cours de chargement
        <div style={{ fontSize: '20px', textAlign: 'center', margin: '20px' }}>Chargement en cours...</div>
      ) : (
        // Affiche le DataTable avec les données si le chargement est terminé
        <DataTable
          columns={columns}
          data={licences}
          pagination={true}
          noDataComponent={"Aucune information trouvée"}
        />
      )}
    </div>
  );
};

export default LicenceListAll;

import React from 'react';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const Popup = ({ open, onClose, message, type }) => {
  return (
    <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 8, // Ajout d'une bordure arrondie
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
  {type === 'success' ? (
    <Box sx={{ color: 'green', mr: 1 }}>
      <CheckCircleIcon fontSize="large" />
    </Box>
  ) : (
    <Box sx={{ color: 'red', mr: 1 }}>
      <ErrorIcon fontSize="large" />
    </Box>
  )}
  <Typography id="modal-title" variant="h6" component="h2" color={type === 'success' ? 'primary' : 'error'}>
    {type === 'success' ? 'Succès' : 'Erreur'}
  </Typography>
</Box>

      <Typography id="modal-description" sx={{ mt: 2, textAlign: 'center' }}>
        {message}
      </Typography>
  
      {/* Bouton "Fermer" en bas */}
      <Button
        onClick={onClose}
        sx={{ marginTop: 2, width: '100%' }} // Bouton prend 100% de la largeur
        variant="contained"
        color={type === 'success' ? 'primary' : 'error'} // Utilisation d'une couleur en fonction du type (success ou error)
      >
        Fermer
      </Button>
    </Box>
  </Modal>
  
  );
};

export default Popup;

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import AuthContext from "../pages/AuthContext";
import Popup from './../components/popup';
import './../styles/UserList.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const { token } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const [updateTrigger, setUpdateTrigger] = useState(false); // Nouvel état pour déclencher la mise à jour


  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    };

    axios.get('https://api.begotech.com/api/v1/users', config)
      .then(response => {
        setUsers(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données des utilisateurs :', error.response.data);
        setLoading(false);
      });
  }, [token,updateTrigger]);

  const columns = [
    { name: 'Nom', selector: row => row.name, sortable: true },
    { name: 'Email', selector: row => row.email, sortable: true },
    { name: 'Rôle', selector: row => row.role, sortable: true },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={() => handleShowEditModal(row)}>
              <EditIcon />
            </button>
            
            <button onClick={() => handleDeleteUser(row.id)}>
              <DeleteIcon />
            </button>
          </div>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  
  const AddUserForm = ({ onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const { token } = useContext(AuthContext);

    const [popupData, setPopupData] = useState({
      isOpen: false,
      message: '',
      type: ''
    });

    const handleAddUser = () => {
      const userData = {
        name: name,
        email: email,
        password: password,
        role: role
      };

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      };

      axios.post('https://api.begotech.com/api/v1/users', userData, config)
        .then(response => {
          console.log('Utilisateur ajouté avec succès :', response.data);
          onClose();
          setUpdateTrigger(!updateTrigger);
        })
        .catch(error => {
          if (error.response && error.response.data) {


            let firstKey;
            for (const key in error.response.data.error) {
              if (error.response.data.error.hasOwnProperty(key)) {
                firstKey = key;
                break;
              }
            }

            setPopupData({
              isOpen: true,
              message: error.response.data.error[firstKey],
              type: 'error'
            });
          } else {
            console.error('Erreur lors de l\'ajout de l\'utilisateur :', error);
          }
        });
    };

    const closePopup = () => {
      setPopupData({
        isOpen: false,
        message: '',
        type: ''
      });
    };

    return (
      <div className="modal-content">
        <h2>Ajouter un utilisateur</h2>
        <form>
          <label>Nom:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

          <label>Mot de passe:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />

          <label>Rôle:</label>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value=""></option>
            <option value="user">Utilisateur</option>
            <option value="admin">Administrateur</option>
            <option value="superadmin">Super Administrateur</option>
          </select>

          <div className="button-container">
            <button type="button" onClick={handleAddUser}>Ajouter</button>

            <Button variant="contained" onClick={onClose} color="error">
              Annuler
            </Button>
          </div>
        </form>

        <Popup
          open={popupData.isOpen}
          onClose={closePopup}
          message={popupData.message}
          type={popupData.type}
        />
      </div>
    );
  };



const EditUserForm = ({ user, onClose }) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [role, setRole] = useState(user.role);
  const { token } = useContext(AuthContext);

  const [ setPopupData] = useState({
    isOpen: false,
    message: '',
    type: ''
  });

  const handleEditUser = () => {
    const userData = {
      name: name,
      email: email,
      role: role
    };

    // Mettez ici la logique pour envoyer la requête PUT avec les données de l'utilisateur
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    };

    axios.put(`https://api.begotech.com/api/v1/users/${user.id}`, userData, config)
      .then(response => {
        // Traitez la réponse de l'API après la modification de l'utilisateur si nécessaire
        console.log('Utilisateur modifié avec succès :', response.data);
        // Fermez le popup modal après la modification de l'utilisateur
        onClose();
        setUpdateTrigger(!updateTrigger);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          // Si l'API a renvoyé des messages d'erreur spécifiques, stockez-les dans l'état 'errors'
        

          let firstKey;
          for (const key in error.response.data.error) {
            if (error.response.data.error.hasOwnProperty(key)) {
              firstKey = key;
              break;
            }
          }

          setPopupData({
            isOpen: true,
            message: error.response.data.error[firstKey],
            type: 'error'
          });
        } else {
          console.error('Erreur lors de la modification de l\'utilisateur :', error);
        }
      });
  };

  return (
    <div className="modal-content">
      <h2>Modifier l'utilisateur</h2>
      <form>
        <label>Nom:</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} />

        <label>Email:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

        <label>Rôle:</label>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="user">Utilisateur</option>
          <option value="admin">Administrateur</option>
          <option value="superadmin">Super Administrateur</option>
        </select>

        <div className="button-container">
          <button type="button" onClick={handleEditUser}>Enregistrer</button>

          <Button variant="contained" onClick={onClose} color="error">
            Annuler
          </Button>
        </div>
      </form>
    </div>
  );
};




  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleShowEditModal = (user) => {
    setSelectedUser(user);
  };

  const handleCloseEditModal = () => {
    setSelectedUser(null);
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const handleDeleteUser = (userId) => {
    setUserToDelete(userId);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDeleteUser = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    };

    axios.delete(`https://api.begotech.com/api/v1/users/${userToDelete}`, config)
      .then(() => {
        setUsers((prevUsers) => prevUsers.filter(user => user.id !== userToDelete));
        setShowDeleteConfirmation(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de l\'utilisateur :', error);
        setShowDeleteConfirmation(false);
      });
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div>
      <h1>Liste des utilisateurs</h1>
        {/* Afficher l'indicateur de chargement si 'loading' est vrai */}
        {loading && <div style={{ fontSize: '20px', textAlign: 'center', margin: '20px' }}>Chargement en cours...</div>}
      
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        <button onClick={handleShowAddModal} style={{ width: '150px', padding: '5px' }}>
          Ajouter un utilisateur
        </button>
      </div>


       {/* Afficher le DataTable si 'loading' est faux */}
       {!loading && (
        <DataTable
          columns={columns}
          data={users}
          pagination={true}
          noDataComponent={"Aucune information trouvée"}
        />
      )}

      {showAddModal && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-button" onClick={handleCloseAddModal}>X</button>
            <AddUserForm onClose={handleCloseAddModal} />
          </div>
        </div>
      )}

      {selectedUser && (
        <div className="modal-overlay">
        <div className="modal">
          <button className="close-button" onClick={handleCloseEditModal}>X</button>
          <EditUserForm user={selectedUser} onClose={handleCloseEditModal} />
        </div>
      </div>

       
      )}

      {showDeleteConfirmation && (
        <div className="modal-overlay">
          <div className="modal">
            <p>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
            <div className="button-container">
              <Button variant="contained" onClick={handleConfirmDeleteUser} color="error">
                Confirmer
              </Button>
              <Button variant="contained" onClick={handleCancelDelete}>
                Annuler
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;


import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AuthContext from "./AuthContext";
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';




function Header(props) {
  const { onDrawerToggle } = props;
  const {logout } = useContext(AuthContext);
  const navigate = useNavigate();

  
  const handleLogout = () => {
    logout();
    // Rediriger l'utilisateur vers la page de connexion après la déconnexion
    navigate("/login");
  };

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
         
            <Grid item>
            <Button variant="contained" onClick={handleLogout} color="warning">
              Déconnexion
            </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0, height: '80px'  }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={6} lg={4} >
              <Typography color="inherit" variant="h5" component="h1">
                Tableau de bord
              </Typography>
            </Grid>
        
          </Grid>
        </Toolbar>
      </AppBar>
      
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;

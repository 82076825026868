

import React, { useState, useContext } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import AuthContext from "../pages/AuthContext";
import './../styles/GenererLicences.css';

const GenererLicences = () => {
  const [nombreCodes, setNombreCodes] = useState('');
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false); // Nouvel état pour contrôler la visibilité du popup
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const { token } = useContext(AuthContext);

  const handleGenerateLicences = () => {
    setLoading(true);
    setPopupVisible(false); // Cacher le popup avant de faire une nouvelle requête

    const config = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    };

    const data = {
      nombre_codes: nombreCodes
    };

    axios.post('https://api.begotech.com/api/v1/licences/auto', data, config)
      .then(response => {
        setLoading(false);
        setPopupType('success'); // Définir le type du popup comme succès
        setPopupMessage(`Généré avec succès ${response.data.codes_licence.length} licences.`);
        setPopupVisible(true); // Afficher le popup
        console.log('Licences générées avec succès :', response.data);
      })
      .catch(error => {
        setLoading(false);
        setPopupType('error'); // Définir le type du popup comme erreur
        setPopupMessage('Erreur lors de la génération des licences.');
        setPopupVisible(true); // Afficher le popup
        console.error('Erreur lors de la génération des licences :', error.response);
      });
  };

  return (
    <div className="container">
      <h1>Générer des Licences</h1>
      <fieldset className="fieldset">
        <div className="form">
          <label>Nombre de codes:</label>
          <input
            type="number"
            value={nombreCodes}
            onChange={(e) => setNombreCodes(e.target.value)}
          />
          <Button variant="contained" onClick={handleGenerateLicences} disabled={loading}>
            Générer
          </Button>
        </div>
      </fieldset>
      {loading && <div className="loading-message">Chargement en cours...</div>}
      {/* Afficher le popup si 'popupVisible' est vrai */}
      {popupVisible && (
        <div className="popup-overlay">
          <div className={`popup ${popupType}`}>
            <div className="popup-message">{popupMessage}</div>
            <button className="popup-close" onClick={() => setPopupVisible(false)}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenererLicences;



import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../pages/AuthContext';
import './../styles/Statistique.css';

const Statistique = () => {
  const [statistiques, setStatistiques] = useState({});
  const { token } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + token
          }
        };

        const response = await axios.get('https://api.begotech.com/api/v1/dashboard', config);
        setStatistiques(response.data.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des statistiques :', error);
      }
    };

    fetchData();
  }, [token]);

  return (
    <div>
      <h2>Statistiques des licences</h2>
      <div className="card expired">
        <h3>Licences expirées</h3>
        <p>{statistiques.licence_off}</p>
      </div>
      <div className="card active">
        <h3>Licences en cours</h3>
        <p>{statistiques.licence_on}</p>
      </div>
      <div className="card unused">
        <h3>Licences non utilisées</h3>
        <p>{statistiques.licence_null}</p>
      </div>
    </div>
  );
};

export default Statistique;
